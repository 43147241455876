import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Form, Label, FormGroup } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

const ViewOrder = ({ type }) => {
  const { sno } = useParams();
  let { orderData } = useSelector((state) => state.OrderReducers);
  const order = orderData.find((order) => order.id == sno);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="Carvaan" title="Orders" breadcrumbItem={`View Orders`} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div data-repeater-list="group-a">
                    <div data-repeater-item className="row w-100">
                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="userId">
                          Payment Id
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{order.payment_id}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="vendorId">
                          User Name
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{order.details[0].first_name}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="tripId">
                          Package Name
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{order.packageDetails.title}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="paidOrUnpaid">
                          Amount
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{order.total_amount}</CardTitle>
                        </Card>
                      </div>


                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="orderDate">
                          Order Date
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{format(new Date(order.created_at), 'dd/MM/yyyy')}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="orderDate">
                          Gmail
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{order.details[0].email}</CardTitle>
                        </Card>
                      </div>

                      <div className="mb-3 col-lg-3">
                        <label className="form-label" htmlFor="orderDate">
                         Phone
                        </label>
                        <Card body className="border">
                          <CardTitle className="h4">{order.details[0].phone}</CardTitle>
                        </Card>
                      </div>

                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewOrder;
