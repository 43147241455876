import React from 'react'
// import { Row, Col, Card, CardBody, CardTitle,Form } from "reactstrap";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import CreateManageForm from './CreateManageForm';

const EditManage = () => {

    return (
        <React.Fragment>
      <CreateManageForm type="Edit"/>
    </React.Fragment>
    )
}

export default EditManage