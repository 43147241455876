import React from 'react'
//Import Breadcrumb

import CreateCouponsForm from './CreateCouponsForm';
const EditCouponsPage = () => {

    return (
        <React.Fragment>
      <CreateCouponsForm type="Edit"/>
    </React.Fragment>
    )
}

export default EditCouponsPage;