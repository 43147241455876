import React from 'react'
//Import Breadcrumb

import CreateCouponsForm from './CreateCouponsForm';
const CreateCouponsPage = () => {

    return (
        <React.Fragment>
      <CreateCouponsForm type="Create"/>
    </React.Fragment>
    )
}

export default CreateCouponsPage;