import React from 'react'
import FaqEditFrom from './FaqEditForm'
//Import Breadcrumb


const FaqEdit = () => {

 
  
    return (
        <React.Fragment>
      <FaqEditFrom type="Edit"/>
    </React.Fragment>
    )
}

export default FaqEdit