import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Card, CardBody, CardTitle } from 'reactstrap';
import { format } from 'date-fns';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupBooking } from '../../store/auth/user_admin_data/actions';
import { getPackage, getGroupBooking } from '../../helpers/fakebackend_helper';
import Loader from '../../components/loader/Loader';
import DataTable from 'react-data-table-component';

const OrderDetails = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
      try {
          const bookingData = [];
          let booking = await getGroupBooking();
          let packageData = await getPackage();
  
          // Ensure packageData is an array before filtering
          if (Array.isArray(packageData)) {
              booking.forEach((book) => {
                  const matchedPackage = packageData.find(pkg => pkg.id === book.group_package_id);
                  if (matchedPackage) {
                      bookingData.push({
                          ...book,            // Keep booking data
                          packageDetails: matchedPackage // Add matched package data
                      });
                  }
              });
          }
          dispatch(setGroupBooking(bookingData)); // Store matched data
          setFilteredData(bookingData)
          setLoader(false);
      } catch (error) {
          console.error("Error fetching data:", error);
          setLoader(false);
      }
  };
  
    useEffect(() => {
        setLoader(true);
        fetchData();
    }, []);

    const generateActionButtons = (row) => (
        <div>
            <Link to={`/viewOrder/${row.id}`}>
                <button className="btn btn-primary mx-2">
                    <i className="ti-eye"></i>
                </button>
            </Link>
        </div>
    );

    const columns = [
        {
            name: 'User Name',
            selector: (row) => row.details[0].first_name,
            sortable: true,
        },
        {
            name: 'Package ',
            selector: (row) => row.packageDetails.title,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.details[0].phone,
            sortable: true,
        },
        {
            name: 'Gmail',
            selector: (row) => row.details[0].email,
            sortable: true,
        },
        {
            name: 'Price',
            selector: (row) => row.total_amount,
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: (row) => format(new Date(row.created_at), 'dd/MM/yyyy'),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => generateActionButtons(row),
            sortable: false,
            width: 'auto',
        },
    ];

    const handleChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        // setSearchTerm(searchTerm);
        const filtered = filteredData.filter((row) =>
            (row.details[0].first_name?.toLowerCase() || "").includes(searchTerm) ||
            (row.packageDetails.title?.toLowerCase() || "").includes(searchTerm) ||
            (row.total_amount?.toLowerCase() || "").includes(searchTerm)
        );
    
        setFilteredData(filtered);
    };    

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        link=""
                        maintitle="Carvaan"
                        title="Users"
                        breadcrumbItem="Users"
                    />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                        <CardTitle className="h4 mb-0">Order Details</CardTitle>
                                        {/* <div className="search-container">
                                            <Input
                                                type="text"
                                                placeholder="Search"
                                                className="search-input"
                                                value={searchTerm}
                                                onChange={handleChange}
                                            />
                                        </div> */}
                                    </div>
                                    {loader ? (
                                        <Loader />
                                    ) : (
                                        <div style={{ minHeight: '80vh' }}>
                                            <DataTable columns={columns} data={filteredData} pagination responsive />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default OrderDetails;
