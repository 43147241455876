import React from 'react'
//Import Breadcrumb
import CarvaanSpecialCreateForm from './CarvanSpecialCreateForm';

const CarvaanSpecialCreate = () => {

    return (
        <React.Fragment>
      <CarvaanSpecialCreateForm type="Create"/>
    </React.Fragment>
    )
}

export default CarvaanSpecialCreate;