import React from 'react'
import FaqEditFrom from './FaqEditForm'
//Import Breadcrumb



const FaqCreate = () => {



  
    return (
        <React.Fragment>
      <FaqEditFrom type="Create"/>
    </React.Fragment>
    )
}

export default FaqCreate