import React from 'react';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditBlogForm from './EditBlogForm';

const EditBlog = () => {


    return (
        <React.Fragment>
       <EditBlogForm type="Edit"/>
        </React.Fragment>
    )
}

export default EditBlog;
